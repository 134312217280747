<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="bg-cll-nav print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex h-30 items-center p-6 pb-0 pt-0 cursor-pointer">
            <!-- Light version -->
            <img [routerLink]="['/']"
                class="w-50 dark:hidden"
                [src]="lightThemeLogoPath"
                alt="logoAltText"/>
            <!-- Dark version -->
            <img [routerLink]="['/']"
                class="hidden w-60 dark:flex"
                [src]="darkThemeLogoPath"
                alt="logoAltText"/>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col">
    <!-- Header -->
    <div class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden">
        <!-- Navigation toggle button -->
        <button 
        mat-icon-button 
        (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!-- <search [appearance]="'bar'"></search> -->
            <!-- <shortcuts></shortcuts>
            <messages></messages>
            <button
                class=""
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-bubble-left-right'"></mat-icon>
            </button>            
            <notifications></notifications> -->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">

        <span class="font-medium text-secondary" [innerHtml]="footerBranding"></span>
        &nbsp;
        &nbsp;
        <span><a title="Change Log" routerLink="/home/change-log" class="cursor-pointer" >
            <mat-icon
            class="icon-size-4 fuse-vertical-navigation-item-icon"
            svgIcon="heroicons_outline:rocket-launch"></mat-icon>
        </a></span>
    </div>

</div>

<!-- Quick chat -->
<quick-chat #quickChat="quickChat"></quick-chat>
