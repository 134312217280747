import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiHttpService } from '@itm/services';
import { Navigation } from 'app/core/navigation/navigation.types';
import { map, Observable, ReplaySubject, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private readonly _httpClient = inject(ApiHttpService);
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);
    private _url_navigation:string =  'core/navigation';

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return this._httpClient.GETT<Navigation>(this._url_navigation)
            .pipe(
                map(x => {
                    if (x.data === undefined) {
                        throw new Error(x.data.error);
                    } else {
                        return x.data.data
                    }
                }),
                //filter(x => !!x.data),
                tap((x) => {
                    // console.log('tapped into nav',x);
                    this._navigation.next(x);
                }),
            );
        // return this._httpClient.get<Navigation>('api/common/navigation').pipe(
        //     tap((navigation) => {
        //         this._navigation.next(navigation);
        //     })
        // );
    }
}
