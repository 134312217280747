import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventPayload, EventType } from '@azure/msal-browser';
import { ItmUserService } from '@itm/services';
import { Subject, filter, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    changeDetection:ChangeDetectionStrategy.Default,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    private readonly _msalBroadcastService = inject(MsalBroadcastService);
    private readonly _itmUserService = inject(ItmUserService);

    ngOnInit(): void {
        
        this._msalBroadcastService.msalSubject$
            .pipe(
                // tap(x => console.log('msalSubject=>', x)),
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                // Casting payload as AuthenticationResult to access account
                const payload = result.payload as AuthenticationResult;
                // debugger;
                // console.log('payload', payload);
                
                localStorage.setItem('idToken',payload.idToken);
                localStorage.setItem('uniqueId',payload.uniqueId);

                this._itmUserService.setUserId(payload.uniqueId);
                // this.#ItmUserService.setUserId('6e182ea8-3a04-4d52-8532-b535848646f2');
            });


            this._msalBroadcastService.msalSubject$
            .pipe(
              filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
              takeUntil(this._destroying$)
            )
            .subscribe((result: any) => {
              // Do something with event payload here
            //   console.log('ACQUIRE_TOKEN_SUCCESS',result);
              const payload = result.payload;
              localStorage.setItem('accessToken',payload.accessToken);
              
            });
    }

    ngOnDestroy(): void {
        this._destroying$.next(null);
        this._destroying$.complete();
    }
}
